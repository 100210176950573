import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
//import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>

        <div class="bg-gradient-to-r from-purple-300 to-blue-200">
          <div class="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
              <div class="border-t border-gray-200 text-center pt-8">
                <h1 class="text-9xl font-bold text-purple-400">404</h1>
                <h1 class="text-6xl font-medium py-8">oops! Seite nicht gefunden</h1>
                <p class="text-2xl pb-8 px-12 font-medium">Oops! Die Seite, nach der Sie suchen, existiert nicht. Sie wurde möglicherweise verschoben oder gelöscht.</p>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
